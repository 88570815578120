<template>
  <v-main class="home">
    <h2>Randotron</h2>
    <v-card elevation="2" v-for="password in passwords" v-bind:key="password.type">
      <v-card-title>{{ password.type }} - {{ password.description }}</v-card-title>
      <v-card-subtitle>{{ password.result }}</v-card-subtitle>
    </v-card>
  </v-main>
</template>

<script>
  // @ is an alias to /src
  export default {
    name: 'Home',
    data() {
      return {
        passwords: [{
          type: 'complex',
          description: 'Harder to guess',
          result: null
        }, {
          type: 'moderate',
          description: 'Easier to guess',
          result: null
        }, {
          type: 'simple',
          description: 'Should only be used as temporary',
          result: null
        }]
      }
    },
    mounted() {
      this.passwords.forEach(password => {
        this.$axios.get(`/api/random/password/${password.type}`).then((res) => {
          password.result = res.data.password
        }).catch(err => {
          console.log(err)
        })
      })
    }
  }
</script>