<template>
  <div>
    <h3>This will be the future home of all quick tools made for General Technical.</h3>

    <template>
  <v-card
    class="mx-auto"
    max-width="344"
    outlined
  >
    <v-list-item three-line>
      <v-list-item-content>
        <v-list-item-title class="text-h5 mb-1">
          Randotron
        </v-list-item-title>
        <v-list-item-subtitle>Random passwords...for now.</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-card-actions>
      <v-btn
        outlined
        rounded
        text
        to="/random"
      >
        Go to randotron
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

  </div>
</template>

<script>
  // @ is an alias to /src
  export default {
    name: 'Home',
    data() {
      return {
        
      }
    },
    mounted() {
      
    }
  }
</script>